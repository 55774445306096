.invoice_row {
  border: 1px solid lightgray;
  border-radius: 6px;
  padding: 0px 10px;
  margin-bottom: 20px !important;
  max-width: 100%;
}

.invoice_row_wide {
  min-width: 530px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download_invoice_btn {
  box-shadow: 2px 4px 4px #0000001f;
  width: 100px;
  border-radius: 5px;
  background-color: #6bc43b;
  color: #fff;
  margin-right: 15px;
  border: none;
  padding: 10px;
  margin-left: auto;
}

.download_invoice_icon {
  cursor: pointer;

  margin-left: auto;
}

.generate_invoice_btn_wrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.generate_invoice_btn {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  background-color: white;
  border: 1px solid lightgray;
}

.generate_invoice_btn:hover {
  background-color: #6bc43b;
  color: white;
}

.invoice_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.invoice_actions {
  display: flex;
  align-items: center;
}

.actions_icon {
  display: none !important;
}

.invoice_row_mobile {
  display: none;
}
.close_icon {
  float: right;
}

@media screen and (max-width: 500px) {
  .invoice_row {
    max-width: 280px;
    min-width: fit-content;
  }
  .invoice_actions {
    display: none;
  }

  .invoice_date {
    float: left;
    font-size: x-small;
    margin-top: 5px;
  }
  .invoice_title_mobile {
    font-weight: bolder;
    margin-bottom: 5px;
  }
  .invoice_row_mobile {
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 15px 15px;
    margin-bottom: 20px !important;
    min-width: fit-content;
    display: block;
  }
  .top_row_mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .pay_mobile_button {
    width: 100%;
    box-shadow: 2px 4px 4px #0000001f;
    border-radius: 5px;
    background-color: #6bc43b;
    color: #fff;
    border: none;
    padding: 10px;
  }
  .download_invoice_icon {
    margin-left: 50px;
  }
  .invoice_row_wide {
    display: none;
  }
}
